import React from 'react'
import Link from 'gatsby-link'
import Card from '../components/Card';
import Testimonial from '../components/Testimonial';
import Section from '../components/Section';
import Sectionnew from '../components/Sectionnew';

import Wave from '../components/Wave';
import staticdata from '../../staticdata.json'
import Cell from '../components/Cell';
import styled from 'styled-components'
import NavBar from '../components/Navbar/Navbar'
import '../components/contact.css'
import Footer from '../components/footer'
import ReactDOM from 'react-dom'
import App from '../components/App'
import Navbar from '../components/Navbar/Navbar'
import Tabs from '../components/Tabs';
import DarkModeSwitch from '../components/switch.js'
import '../components/dms.scss'
import {mode, setMode} from '../components/switch.js'
import Head from '../components/head'
import { Helmet } from 'react-helmet'
import { JsonLd } from '../components/JsonLd'
import Toasty from '../components/ToastDemo'


const SectionCaption = styled.p`
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: #94A4BA;
  text-align: center;
`

const Button = styled.button`
    background: linear-gradient(102.24deg, #ffdd55 0%, #ffdd55 100%);
    box-shadow: 0px 10px 20px rgba(101, 41, 255, 0.15);
    border-radius: 30px;
    color: #333333;
    border: none;
    padding: 16px 60px;
    font-weight: 600;
    font-size: 16px;
    justify-self: center;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:hover {
        box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
        transform: translateY(-3px);
       
    }
`
const FeaturesButton = styled.button`
padding: 8px 20px;
font-size: 16px;
background: linear-gradient(102.24deg, #9B51E0 0%, #3436E7 100%);
border: none;
font-weight: 700;
letter-spacing:0px;
border-radius: 10px;
outline: none;
cursor: pointer;
transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
color:#f1f1f1;
margin: 0px 20px;
margin-top:50px;
}
`

const SectionCellGroup = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  padding: 0 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`





const IndexPage = () => (

  
  <div>
    

  
   
  <Head title="Bespoke Catering Services"  description="Bespoke Catering Services, cooking classes, French catering, Senegal catering, English Catering ."/>
  
  <JsonLd>
          {{
            '@context': 'https://schema.org',
            '@type': 'Organization',
            '@id': 'https://www.timasflavours.com/#organization',
            url: 'https://www.timasflavours.com',
            name: 'Timas Flavours',
            contactPoint: {
              '@type': 'ContactPoint',
              
              contactType: 'Customer service',
            },
            
         
          }}
        </JsonLd>

<Navbar />



    
    <div className="Hero">
  
      <div className="HeroGroup">
        <h1>Professional Catering Services</h1>
        <p>A little taste of Senegal, France and England all packed together, lot of flours, lot of love, “un je ne sais quoi” full of flavours !</p>
       <div><Link to="/menu">View Menu</Link></div>
        <div><Toasty></Toasty></div>

        <Wave />
      </div>
    </div>
   

  

   

    


 <div className="myservices">
  <div className="servicetitle">
  <h2>CATERING SERVICES</h2>

  </div>
  <div className="mycontent">
<div className="myitem"> 
<Link to="/cooking-classes"><img className="serviceimage" src='/images/classes.svg' /></Link>
<p>Cooking Classes</p>
</div>
<div className="myitem">
<Link to="/corporate-catering"><img className="serviceimage" src='/images/corporate-01.svg' /></Link>
  <p>Corporate Catering</p></div>
<div className="myitem">
<Link to="/events-catering"><img className="serviceimage" src='/images/catering-01.svg' /></Link>
  <p>Events <br></br>Catering</p>
</div>
<div className="myitem">
<Link to="/kids-party"><img className="serviceimage" src='/images/kids-party.svg' /></Link>
  <p>Kids <br /> Party</p>
</div>

  </div>

<div className="myareas">

</div>
  </div>

   
    
     <Footer />
     

  </div>
)

export default IndexPage
