import React from 'react'
import { ToastProvider, useToasts } from 'react-toast-notifications'

import styled from 'styled-components'

const Button = styled.button`
position:relative;
    background: linear-gradient(102.24deg, #ff2a2a 0%, #ff2a2a 100%);
    box-shadow: 0px 10px 20px rgba(101, 41, 255, 0.15);
    border-radius: 30px;
    color: #fff;
    border: none;
    margin-top:20px;
    padding: 16px 60px;
    font-weight: 600;
    font-size: 16px;
    justify-self: center;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:hover {
        box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
        transform: translateY(-3px);
       
    }
`

const FormWithToasts = ({ content }) => {
  const { addToast } = useToasts()
  return (
    <Button onClick={() => addToast(' During the current Covid situation Tima’s flavours offer you a remote service! Order your treat box now and get it delivered safely to your door or collect from E13! “ Visit our Instagram and Facebook page for more info or hit the contact button with your details.', {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout:'10000', 
    })}>
Coronavirus Update    </Button>
  )
}

const Toasty = () => (
    <ToastProvider>
      <FormWithToasts />
    </ToastProvider>
  )

  export default Toasty;